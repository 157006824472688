import React, {useState, useEffect} from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
// imports styles
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/index.css';
import '../css/mobile.css'
// imports components
import { Container, Navbar } from 'react-bootstrap';

const IndexPage = () => {

  function Header(){
    return (
      <Navbar className="background-dark color-white">
        <Container>
          <Navbar.Brand href="/">
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Flogo.png?alt=media&token=bec7e4e2-bca8-475e-8501-d884dc96078d" 
              alt=""
              className="img-logo my-3"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <div className="row">
                <div className="col-6">
                  <a href="/portafolios" className="text-decoration-none my-2"><span className="text-white">Portafolios</span></a>
                </div>
                <div className="col-6">
                  <div className="row mt-1">
                    <div className="col-4">
                      <a href="https://www.linkedin.com/in/mad-portafolios-308044255/" target="_blank">
                        <img 
                          src="https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fin-icon.png?alt=media&token=830481e2-6508-4985-9822-f5e9eb3d68ca" 
                          alt=""
                          className="img-icon" 
                        />
                      </a>
                    </div>
                    <div className="col-4">
                      <a href="https://www.facebook.com/PortafolioDG.UDB" target="_blank">
                          <img 
                              src="https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Ffb-icon.png?alt=media&token=2214d615-b20c-4eba-b97b-ef6c6d475e3f" 
                              alt="" 
                              className="img-footer"
                          />
                      </a>
                    </div>
                    <div className="col-4">
                      <a href="https://www.instagram.com/portafoliodg.udb/" target="_blank">
                        <img 
                          src="https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fig-icon.png?alt=media&token=7b84cf66-e61c-4219-92aa-8ed8e64f7655" 
                          alt=""
                          className="img-icon"  
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }

  return(
    <>
      <Layout>
        <Header />
        <div className="container-fluid background-dark color-white">
          <div className="row">
            <div className="col-md-8">
              <div className="container p-5">
                <div className="row">
                  <div className="col-md-12">
                  <h3 className="size-year font-family-archivo">
                    <span>
                      <img 
                        className="star-size mt-2"
                        src="https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fstar.png?alt=media&token=4c0e61eb-0ade-4a4c-98e2-b9f34a2862b1" 
                        alt="" 
                      />
                    </span> 
                    <span className="m-3">2022</span>
                  </h3>
                  <h1 className="font-size-xl font-family-tenor-sans"><span className="font-family-archivo"><b>Celebramos</b></span> la creatividad</h1>
                  <p className="font-size-small font-family-archivo">Muestra anual de portafolios que celebra la creatividad de los estudiantes de la Universidad Don Bosco y muestra sus talentos.</p>
                  </div>
                </div>
              </div>
                
            </div>
            <div className="col-md-4">
              <div className="container p-md-5 p-lg-5 mt-md-5 mt-lg-5">
                <div className="container">
                  <div className="row">
                    <div className="col-11">
                      <div className="card background-dark border-white">
                          <div className="card-body">
                            <p className="font-size-md font-family-archivo-semibold">Explora todos los portafolios de <span className="font-family-tenor-sans">los creativos</span> según sus categorías</p> 
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-10 font-family-archivo-semibold">
                                <a href= '/portafolio/editorial' target="_blank" className="badge mx-2 mt-2 text-decoration-none bg-color-editorial bg-color-base text-black border-radius-bagde px-3 py-1">Editorial</a>
                                <a href= '/portafolio/multimedia' target="_blank" className="badge mx-2 mt-2 text-decoration-none bg-color-multimedia bg-color-base text-white border-radius-bagde px-3 py-1 ">Multimedia</a>
                                <a href= '/portafolio/ilustration' target="_blank" className="badge mx-2 mt-2 text-decoration-none bg-color-ilustration bg-color-base text-black border-radius-bagde px-3 py-1 my-1">Ilustración</a>
                                <a href= '/portafolio/branding' target="_blank" className="badge mx-2 mt-2 text-decoration-none bg-color-branding bg-color-base text-white border-radius-bagde px-3 py-1 my-1">Branding</a>
                                <a href= '/portafolio/sponsor' target="_blank" className="badge mx-2 mt-2 text-decoration-none bg-color-sponsor bg-color-base text-white border-radius-bagde px-3 py-1 my-1">Publicidad</a>
                                <a href= '/portafolio/uxui' target="_blank" className="badge mx-2 mt-2 text-decoration-none bg-color-ux-ui bg-color-base text-white border-radius-bagde px-3 py-1 my-1">UX/UI</a>
                                <a href= '/portafolio/3d' target="_blank" className="badge mx-2 mt-2 text-decoration-none bg-color-3d bg-color-base text-white border-radius-bagde px-3 py-1 my-1">3D</a>
                                </div>
                              </div>
                            </div>
                            <div className="container p-4">
                              <div className="row">
                                <a href="/portafolios" className="btn button-default btn-sm btn-block"> 
                                  <span className="text-button">
                                    Ver todos los portafolios
                                  </span> 
                                  <span>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Farrow--right.png?alt=media&token=e628aaf8-d01c-43c8-b03b-d809b2e79a8a" 
                                    alt="" 
                                    className="img-button no-margin-padding"
                                    />
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <footer className="background-dark index">
          <img 
              src="https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2FDT-Frame-GIF%201.gif?alt=media&token=cc9a536f-c547-481d-9a5d-c7fc1e5ac8e9" 
              alt="" 
              className="gif-index no-margin-padding d-none d-sm-none d-md-block d-lg-block"
            />
        </footer>
      </Layout> 
    </>
  )
}




export const Head = () => <Seo title="Home" />

export default IndexPage
